import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/modules/Seo";
import Hero from "../components/modules/Hero";
import config from "../../data/SiteConfig";
import GriegacademyVideos from "../components/modules/GriegacademyVideos";

export default class PostTemplate extends React.Component {
  render() {
    const { slug } = this.props.pageContext;
    const postNode = this.props.data.markdownRemark;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }
    if (!post.category_id) {
      post.category_id = config.postDefaultCategoryID;
    }
    return (
      <Layout>
        <div className="post">
          <Helmet>
            <title>{`${post.title} | ${config.siteTitle}`}</title>
          </Helmet>
          <Seo postPath={slug} postNode={postNode} postSEO />
          <Hero fullWidth={post.img.childImageSharp.gatsbyImageData} className="hero"/>
          <div className="flex center column basePad">
            <div className="article">
              <h1>{post.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: postNode.html }} />
              {post.title==="The Grieg Academy" ? <GriegacademyVideos />: ''}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        img {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        date
        category
        tags
        quote
        author
      }
      fields {
        slug
        date
      }
    }
  }
`;
