import React, {Fragment} from 'react';

const GriegacademyVideos = () => {
  return(
    <>
      <h2 style={{padding: '1rem 0 0'}}>Previous students</h2>
      <div className="videoCard">
        <h3 style={{padding: '1rem 0 0'}}>Thomas Valeur: Fuoco by Rolan Dyens</h3>
        <div className="videoWrapper">
          <iframe width="560" height="349" src={"https://www.youtube.com/embed/6ADws-5OGy0"} frameBorder="0" allowFullScreen></iframe>
        </div>
      </div>
      <div className="videoCard">
        <h3 style={{padding: '2rem 0 0'}}>Gjermund Fredheim: Variations on a Moldovian hora by Ian Krouse</h3>
        <div className="videoWrapper">
          <iframe width="560" height="349" src={"https://www.youtube.com/embed/F0QLnwNiOL0"} frameBorder="0" allowFullScreen></iframe>
        </div>
      </div>
      <div className="videoCard">
        <h3 style={{padding: '2rem 0 0'}}>Thomas Heimstad: Fantasia no.1 by Telemann</h3>
        <div className="videoWrapper">
          <iframe width="560" height="349" src={"https://www.youtube.com/embed/rjNwlPm3QmA"} frameBorder="0" allowFullScreen></iframe>
        </div>
      </div>
      <div className="videoCard">
        <h3 style={{padding: '2rem 0 0'}}>Fredheim/Kvasnes Duo</h3>
        <p style={{margin: 0}}>Harald Kvasnes and Gjermund Fredheim have won prizes in several international guitar competitions such as:</p>
        <p style={{margin: 0}}>-International guitar competition in Olsztyn, Poland 2009 : 1. Prize.</p>
        <p style={{margin: 0}}>-Concurso Europeo di Chitarra Classica "Citta di Gorizia", Premio Enrico Mercatali 2009: 2. Prize.</p>
        <p style={{margin: 0}}>-Festival Internazionale della Chitarra Siracusa 2009, Concorso Internazionale "Giovani Musicisti" : 1.Prize.</p>
        <div className="videoWrapper">
          <iframe width="560" height="349" src={"https://www.youtube.com/embed/_IGR7DmYsr8"} frameBorder="0" allowFullScreen></iframe>
        </div>
      </div>
    </>
  )
}
export default GriegacademyVideos;
